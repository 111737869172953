.responsive_carousel_container {
    position: relative;
    width: 64vw;
    z-index: 1;
}

.responsive_carousel {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.responsive_carousel_animated_divs {
    position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
    height: 0;
    width: 100%;
    z-index: 0;
}

.responsive_carousel_animated_divs > iframe {
    position: absolute;
	top: 0;
	left: 0;
	width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1023px) {
    .responsive_carousel_container {
        width: 100vw;
    }
}


@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .responsive_carousel_container {
        width: 100vw;
    }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .responsive_carousel_container {
        width: 64vw;
    }
}