body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  background-color: #1F1F1F;
  scroll-behavior: smooth;
  overflow: overlay;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #b68b57;
}

h1 {
  font-size: 52px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Libre Baskerville', serif; */
}

h2 {
  font-size: 32px;
  font-weight: 500;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Libre Baskerville', serif;
}

h3 {
  font-size: 28px;
  font-weight: 500;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Libre Baskerville', serif; */
}

p {
  font-size: 18px;
  margin: 0;
  color: #999999;
  line-height: 1.4em;
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Libre Baskerville', serif; */
}

.small_text {
  font-size: 16px;
  margin-top: 20px;
}

video {
  pointer-events: none;
  opacity: 1;
  width: 100%;
}

.special_h1 {
  font-size: 92px;
}

.divider_section {
  height: 200px;
  background-color: #1F1F1F;
}

.main_logo {
  width: 180px;
}

.card_ratio {
  width: 65vw;
  height: 75vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.unselectable {
  -moz-user-select: none; 
  -webkit-user-select: none; 
  -ms-user-select:none; user-select:none;
  -o-user-select:none;
}

.header_page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.header_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 680px;
}
 
.header_text {
  width: 623px;
  color: #fff;
  margin: 15px 0 100px 0;
}

.text_loop_styles {
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  margin-bottom: 30px;
}

.cta_button {
  cursor: pointer;
  width: 200px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #76C09B;
  transition: 0.1s ease-in-out;
}

.cta_text {
  color: #fff;
}

.cta_button:hover {
  transform: translateY(-3px);
}

.cta_button:active {
  transform: translateY(0px);
}

.header_interactive_text {
  color: #fff;
}

.header_video_container {
  overflow: hidden;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.read_more_h3 {
  text-align: center;
  color: #fff;
  margin: 70px 0;
}

.black_overlay {
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.4;
  position: absolute;
}

.nav_style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  height: 60px;
  background-color: #1F1F1F;
}

.icon_style {
  margin: 0 10px;
}

.link_style {
  text-decoration: none;
  margin: 0 15px;
}

.main_section {
  min-height: 700px;
  display: flex;
  position: relative;
  background-color: #1F1F1F;
  flex-direction: column;
  align-items: center;
}

.small_text_group {
  width: 70vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  margin: 5% 0;
}

.short_h3{
  padding-right: 50px;
  width: 30%;
}

.short_desc {
  width: 60%;
}

.unbold_header2 {
  font-size: 32px;
  font-family: 'Libre Baskerville', serif;
  font-weight: 400;
}

.mission_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mission_statement_container{
  width: 1000px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.read_more_rellax_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mission_read_more_bttn {
  margin-top: 40px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  transform: scale(0.9);
  border: 1px solid #E9B16D;
  width: 200px;
  padding: 15px;
  border-radius: 50px;
  color: #E9B16D;
}

.mission_read_more_bttn:hover {
  transform: scale(1);
  background-color: #E9B16D;
  color: #1F1F1F;
}

.mission_read_more_bttn:active {
  transform: scale(0.9);
}

.read_more_texts_container {
  width: 550px;
  transition: 0.4s ease-in-out;
}

.read_more_inactive {
  opacity: 0;
}

.read_more_active {
  opacity: 1;
}

.display_set_none {
  display: none;
}

.display_set_block {
  display: block;
}

.paragraph_sub_header {
  margin-bottom: 20px;
}

.custom_img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.custom_img {
  width: 100%;
}

.custom_img_header_container {
  width: 100%;
  display: flex;
  position: relative;
  top: 15px;
}

.img_text_overlap_container {
  display: flex;
  align-items: center;
  width: 100%;
}

.brew_section {
  width: 100%;
}

.section_selection_img_container {
  height: 85%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_selection_text {
  margin-top: 15px;
}

.brews_text_container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
}

.carousel_ui_container,
.inner_content,
.inner_content_bttn_container {
  width: 350px;
}

.inner_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 450px;
}

.arrow_icon {
  border-radius: 25px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s ease-in-out transform;
  cursor: pointer;
  transform: scale(1);
}

.arrow_icon:hover {
  transform: scale(1.2);
}

.arrow_icon:active {
  transform: scale(1);
}

.mixes_elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
}

.mixes_section {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.mixes_arrow_container {
  width: 100%;
  display: flex;
  justify-content: space-between;  
  margin-top: 10px;
}

.brews_text_container,
.mixes_text_container {
  width: 36%;
}

.mixes_text_container {
  display: flex;
  justify-content: center;
  position: relative;
}

.mixes_header_description_container {
  height: 400px;
}

.interactive_text_center_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 350px;
}

.interactive_text_center_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}

.interactive_text_value {
  text-align: left;
}

.map_container {
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  height: 80vh;
  position: relative;
}

.map_text {
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.map_txt_statement {
  margin: 10px 0 0 0;
}

.location_section {
  display: flex;
  background-color: #1F1F1F;
  flex-direction: column;
  align-items: center;
  position: relative;
}

footer {
  background-color: #1F1F1F;
  height: 100px;
  padding: 0 5%;
}

.footer_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 45px;
}

.footer_notes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px 0;
}

.footer_elems {
  width: 33%;
}

.footer_arrow {
  display: flex;
  justify-content: center;
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  top: 25px;
}


.footer_text {
  color: #494949;
  margin-top: 0;
  font-size: 14px;
}

.footer_line_break_arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.designer_info_styles {
  text-align: right;
}

.line_break {
  border: 1px solid #494949;
  width: 100%;
}

.unfaded_item {
  transition: 0.2s ease-in-out;
  opacity: 1;
}

.faded_item {
  opacity: 0;
}

.video_back {
  background-size: cover;
  background-repeat: no-repeat;
}

.carousel_ui_container {
  display: flex;
  justify-content: space-between;
}

.carousel_directionals {
  background-color: none;
  z-index: 1;
  display: flex;
  align-items: center;
}

.carousel_arrow,
.circle_glow {
  width: 55px;
  height: 55px;
  border-radius: 28px;
}

.carousel_arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.circle_glow {
  position: absolute;
  transition: 0.2s ease-in-out;
  transform: scale(0);
  opacity: 0;
  z-index: 1;
}

.arrow_glow {
  background-color: #E9B16D;
  transform: scale(1);
  opacity: 1;
}

.google_map_section {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.inidicator_nodes_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator_nodes {
  height: 8px;
  width: 8px;
  /* border-radius: 10px; */
  border: 1px solid #E9B16D;
  margin: 0 8px;
  transition: 0.2s ease-in-out;
  transform: rotate(45deg) scale(1);
}

.indicator_nodes_filled {
  background-color: #E9B16D;
  transform: rotate(135deg) scale(1.3);
}

.iframe_dimensions_controller {
  /* height: 85vh; */
  width: 64vw;
  overflow: hidden;
}

 .iframe_container {
  position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
  height: 0;
  overflow: hidden;
} 

.iframe_container > iframe {
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.inidicator_nodes_white_container {
  width: 140px;
  z-index: 3;
}

.indicator_white_extra_layer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.indicator_nodes_white {
  height: 8px;
  width: 8px;
  border: 1px solid #E9B16D;
  /* border-radius: 10px; */
  transition: 0.4s ease-in-out;
  margin: 0 8px;
  transform: rotate(45deg) scale(1);
}

.indicator_nodes_filled_white {
  background-color: #E9B16D;
  transform: rotate(135deg) scale(1.3);
}

.brews_section {
  width: 100%;
}

/** media queries **/

/* Tablet and mobile */

@media only screen and (max-width: 1023px) {
  .brews_section {
    margin-bottom: 50px;
  }
  .mixes_text_container {
    margin-top: 20px;
  }
  .header_video {
    height: 100%;
    width: auto;
  }
  p {
    font-size: 16px;
  }
  h3 {
    font-size: 24px;
  }
  .header_video_container {
    height: 100%;
    width: auto;
  }
  .header_page {
    overflow: hidden;
  }
  .header_interactive_text {
    font-size: 32px;
  }
  .header_content {
    width: 100%;
  }
  .main_section {
    padding: 0 10%;
  }
  .text_loop_styles{
    width: 80vw;
  }
  .mission_statement_container {
    width: 100%;
  }
  .unbold_header2 {
    font-size: 24px;
  }
  .read_more_texts_container {
    width: 100%;
  }
  .img_text_overlap_container {
    flex-direction: column;
  }
  .custom_img_header_container {
    width: 100%;
    position: relative;
    top: 0px;
    justify-content: center;
  }
  .special_h1 {
    font-size: 50px;
  }
  .carousel_ui_container {
    width: 100%;
  }
  .inner_content {
    width: auto;
    height: 480px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .brews_text_container {
    margin-top: 20px;
    margin-left: 0px;
  }
  .mobile_brews_description {
    margin-top: 30px;
  }
  .mixes_section {
    width: 100%;
    align-items: center;
    justify-content: center;
  }  
  .mixes_elements {
    width: 100%;
    flex-direction: column;
  }
  .mixes_arrow_container {
    margin-top: 60px;
  }
  .interactive_text_center_container {
    width: 100%;
  }
  .mixes_description {
    width: 100%;
  }
  .map_container {
    width: 100%;
    align-items: center;
    height: 40vh;
  }
  .map_text {
    padding: 0 5%;
  }
  .footer_nav {
    flex-direction: column;
    top: 10px;
  }
  .footer_notes {
    flex-direction: column;
    align-items: center;
    padding: 25px 0;
  }
  .footer_elems {
    text-align: center;
    width: 100%;
  }
  .footer_text {
    margin: 0;
    font-size: 14px;
  }
  .footer_email {
    margin-bottom: 15px;
  }
  .arrow_glow {
    background-color: #E9B16D;
    transform: scale(0);
    opacity: 0;
  }
  .brews_text_container,
  .mixes_text_container {
    width: auto;
  }
  .iframe_dimensions_controller {
    width: 100vw;
  }
  .mission_read_more_bttn:hover {
    transform: scale(1);
    background-color: #1F1F1F;
    color: #E9B16D;
  }
  
  .mission_read_more_bttn:active {
    transform: scale(0.9);
  }
}

/* Portrait */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .brews_section {
      margin-bottom: 50px;
    }
    .mixes_text_container {
      margin-top: 20px;
    }
    .header_video {
      height: 100%;
      width: auto;
    }
    p {
      font-size: 16px;
    }
    h3 {
      font-size: 24px;
    }
    .header_video_container {
      height: 100%;
      width: auto;
    }
    .header_page {
      overflow: hidden;
    }
    .header_interactive_text {
      font-size: 32px;
    }
    .header_content {
      width: 100%;
    }
    .main_section {
      padding: 0 10%;
    }
    .text_loop_styles{
      width: 80vw;
    }
    .mission_statement_container {
      width: 100%;
    }
    .unbold_header2 {
      font-size: 24px;
    }
    .read_more_texts_container {
      width: 100%;
    }
    .img_text_overlap_container {
      flex-direction: column;
    }
    .custom_img_header_container {
      width: 100%;
      position: relative;
      top: 0px;
      justify-content: center;
    }
    .special_h1 {
      font-size: 50px;
    }
    .carousel_ui_container {
      width: 100%;
    }
    .inner_content {
      width: auto;
      height: 480px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    .brews_text_container {
      margin-top: 20px;
      margin-left: 0px;
    }
    .mobile_brews_description {
      margin-top: 30px;
    }
    .mixes_section {
      width: 100%;
      align-items: center;
      justify-content: center;
    }  
    .mixes_elements {
      width: 100%;
      flex-direction: column;
    }
    .mixes_arrow_container {
      margin-top: 60px;
    }
    .interactive_text_center_container {
      width: 100%;
    }
    .mixes_description {
      width: 100%;
    }
    .map_container {
      width: 100%;
      align-items: center;
      height: 40vh;
    }
    .map_text {
      padding: 0 5%;
    }
    .footer_nav {
      flex-direction: column;
      top: 10px;
    }
    .footer_notes {
      flex-direction: column;
      align-items: center;
      padding: 25px 0;
    }
    .footer_elems {
      text-align: center;
      width: 100%;
    }
    .footer_text {
      margin: 0;
      font-size: 14px;
    }
    .footer_email {
      margin-bottom: 15px;
    }
    .arrow_glow {
      background-color: #E9B16D;
      transform: scale(0);
      opacity: 0;
    }
    .brews_text_container,
    .mixes_text_container {
      width: auto;
    }
    .iframe_dimensions_controller {
      width: 100vw;
    }
    .mission_read_more_bttn:hover {
      transform: scale(1);
      background-color: #1F1F1F;
      color: #E9B16D;
    }
    
    .mission_read_more_bttn:active {
      transform: scale(0.9);
    }
}

/* Landscape */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .header_video {
      height: 100%;
      width: auto;
    }
    .header_video_container {
      width: 100%;
    }
    .mixes_section {
      margin-top: 150px;
    }
    .custom_img_header_container {
      /* right: 0; */
      position: relative;
      left: 10%;
      width: 30%;
    }
    .ipadpro_positioning {
      left: 28%;
    }
    
    .mission_read_more_bttn:hover {
      transform: scale(1);
      background-color: #1F1F1F;
      color: #E9B16D;
    }
    
    .mission_read_more_bttn:active {
      transform: scale(0.9);
    }
}

/* @media only screen and (min-width: 961px) and (max-width: 1919px) {
    
}

@media only screen and (min-width: 1920px) {
    
} */